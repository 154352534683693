import { useRef } from 'react';

import noImagePlaceholder from '../../../../assets/no-image-placeholder.png';
import { useGetUserSocials } from '../../../../hooks/api/users/useGetUserSocials';
import css from './FollowingSection.module.css';
import { CarouselNavigationButtons, NamedLink } from 'components';

const FollowingSection = () => {
  const { data: userSocials, isLoading } = useGetUserSocials();
  const following = userSocials?.following || [];
  const defaultFollowing = userSocials?.defaultFollowing || [];
  const results = isLoading
    ? new Array(8).fill({
        id: '',
        imageUrl: noImagePlaceholder,
        handle: '',
      })
    : [
        ...following,
        ...defaultFollowing.sort((a, b) =>
          a.id === '6681ae6f-5a19-4f43-83cb-31698ea11f84' ? -1 : 1
        ),
      ];

  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <div className={css.root}>
      <div className={css.items} ref={carouselRef}>
        {results.map((user, i) => {
          const Component = user.id ? NamedLink : 'div';

          return (
            <Component
              name="ProfilePage"
              params={{ id: user.id || '0' }}
              key={i}
              className={css.item}
            >
              <picture>
                <source srcSet={user.imageUrl} />
                <img src={noImagePlaceholder} alt={user.handle} />
              </picture>
              <p>{user.handle}</p>
            </Component>
          );
        })}
      </div>
      <CarouselNavigationButtons carouselRef={carouselRef} />
    </div>
  );
};

export default FollowingSection;
