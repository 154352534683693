import classNames from 'classnames';
import { ReactNode } from 'react';

import LandingSection from '../LandingSection/LandingSection';
import css from './ShowcaseCarousel.module.css';
import { ImageLazy, NamedLink, PrimaryButton } from 'components';
import { RouteName } from 'routing/routeConfiguration';
import { cn } from 'util/cn';

const ShowcaseCarousel: React.FC<{
  title?: ReactNode;
  itemClassName?: string;
  items: {
    title: ReactNode;
    imgSrc: string;
    imgBlurSrc?: string;
    linkParams?: {
      name: RouteName;
      to?: { search: string };
      params?: any;
    };
    linkClassName?: string;
    imageClassName?: string;
    button?: ReactNode;
  }[];
}> = ({ title, itemClassName, items = [] }) => {
  return (
    <LandingSection title={title}>
      {items.map(
        ({ title, imgSrc, imgBlurSrc, linkParams, linkClassName, imageClassName, button }, i) => {
          const LinkComponent = linkParams ? NamedLink : 'div';

          return (
            <LinkComponent
              className={cn(css.item, itemClassName, linkClassName)}
              key={i}
              {...(linkParams as any)}
            >
              <ImageLazy
                className={cn(css.item__image, imageClassName)}
                src={imgSrc}
                blurSrc={imgBlurSrc}
                alt={String(title)}
              />
              <div className={css.item__content}>
                <h3 style={{ fontSize: 'inherit' }}>{title}</h3>
                {button}
              </div>
            </LinkComponent>
          );
        }
      )}
    </LandingSection>
  );
};

export default ShowcaseCarousel;
